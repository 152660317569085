<template>
    <div class="preview_area show_sidebar">
        <div class="global_setting">
            <Form @submit="handleSubmit" :validation-schema="schema" v-slot="{ errors }" class="tabs_content">
                <div class="action_area">
                    <div class="section_header mb-0">
                        <h2>Manual Add Contacts</h2>
                    </div>
                    <button type="button" class="close_btn" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
                    <div class="setting_wpr fld_box">
                        <div class="form_grp col-3">
                            <div class="group_item">
                                <label class="input_label">First Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.first_name }">
                                    <Field autocomplete="off" name="first_name" v-model="form.first_name" type="text" placeholder="John" />
                                </div>
                                <ErrorMessage name="first_name" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">Last Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.last_name }">
                                    <Field autocomplete="off" name="last_name" v-model="form.last_name" type="text" placeholder="Doe" />
                                </div>
                                <ErrorMessage name="last_name" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">Gender</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.gender }">
                                    <Field autocomplete="off" name="gender" v-model="form.gender" :class="{ 'has-error': errors.gender }">
                                        <multiselect
                                            v-model="form.gender"
                                            :options="genderList"
                                            value-prop="value"
                                            label="title"
                                            :searchable="true"
                                            placeholder="Select gender"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="gender" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Mobile Number</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.number }">
                                    <!-- <input type="text" v-model="form.number" /> -->
                                    <vue-tel-input :value="form.number" @input="handlePhoneNumber"></vue-tel-input>
                                </div>
                                <ErrorMessage name="number" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">E-mail</label>
                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                    <Field autocomplete="off" name="email" type="email" v-model="form.email" placeholder="john.doe@onboardme.io" />
                                    <span class="prefix"><i class="fas fa-envelope"></i></span>
                                </div>
                                <ErrorMessage name="email" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp col-3">
                            <div class="group_item">
                                <label class="input_label">Segment</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.status }">
                                    <Field autocomplete="off" name="status" v-model="form.status" :class="{ 'has-error': errors.status }">
                                        <multiselect
                                            v-model="form.status"
                                            :groups="true"
                                            :searchable="true"
                                            :options="statusesGroup"
                                            valueProp="value"
                                            label="title"
                                            placeholder="Select status"
                                            :group-select="false"
                                            group-label="type"
                                            group-options="items"
                                            :group-hide-empty="true"
                                            :loading="statusTabsLoader"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span class="status-tabs-header">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="status" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">Temperature</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.temperature }">
                                    <Field autocomplete="off" name="temperature" v-model="form.temperature" :class="{ 'has-error': errors.temperature }">
                                        <multiselect
                                            v-model="form.temperature"
                                            :options="temperatureList"
                                            value-prop="value"
                                            label="title"
                                            :searchable="true"
                                            placeholder="Select temperature"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="temperature" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">Last Action</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.last_action }">
                                    <Field autocomplete="off" name="last_action" v-model="form.last_action" :class="{ 'has-error': errors.last_action }">
                                        <multiselect
                                            v-model="form.last_action"
                                            :options="lastActions"
                                            value-prop="value"
                                            label="label"
                                            :searchable="true"
                                            placeholder="Select last action"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="last_action" class="text-danger" />
                            </div>
                        </div>
                        <!-- <div v-show="more"> -->
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Business Name</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.business_name }">
                                        <Field autocomplete="off" name="business_name" type="text" v-model="form.business_name" placeholder="Business Name Here.." />
                                        <span class="prefix"><i class="fas fa-briefcase"></i></span>
                                    </div>
                                    <ErrorMessage name="business_name" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Business Number</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.business_number }">
                                        <input type="text" v-model="form.business_number" />
                                    </div>
                                    <ErrorMessage name="business_number" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Address</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.address }">
                                        <Field autocomplete="off" name="address" type="text" v-model="form.address" placeholder="Address Here.." />
                                    </div>
                                    <ErrorMessage name="address" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">City</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.city }">
                                        <Field autocomplete="off" name="city" v-model="form.city" type="text" placeholder="Enter City Here.." />
                                    </div>
                                    <ErrorMessage name="city" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp col-3">
                                <div class="group_item">
                                    <label class="input_label">State</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.state }">
                                        <Field autocomplete="off" name="state" type="text" v-model="form.state" placeholder="Enter State Here.." />
                                    </div>
                                    <ErrorMessage name="state" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Zip Code</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.zipcode }">
                                        <Field autocomplete="off" name="zipcode" type="text" v-model="form.zipcode" placeholder="Enter Zip Code Here.." />
                                    </div>
                                    <ErrorMessage name="zipcode" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Country</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.country }">
                                        <Field autocomplete="off" name="country" v-model="form.country" :class="{ 'has-error': errors.country }">
                                            <multiselect
                                                v-model="form.country"
                                                :options="countries"
                                                value-prop="code"
                                                label="country"
                                                :searchable="true"
                                                placeholder="Select country"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="country" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp col-3">
                                <div class="group_item">
                                    <label class="input_label">Timezone</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.timezone }">
                                        <Field autocomplete="off" name="timezone" v-model="form.timezone" :class="{ 'has-error': errors.timezone }">
                                            <multiselect
                                            v-model="form.timezone"
                                            :options="timezones"
                                            valueProp="code"
                                            label="timezone"
                                            :searchable="true"
                                            >
                                            </multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="timezone" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Date of Birth</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.date_of_birth }">
                                        <Field autocomplete="off" name="date_of_birth" v-model="form.birthdate">
                                            <datepicker v-model="form.birthdate" autoApply timezone="UTC" placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="date_of_birth" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Source</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.source }">
                                        <Field autocomplete="off" name="source" v-model="form.source" :class="{ 'has-error': errors.source }">
                                            <multiselect
                                                v-model="form.source"
                                                :options="sourceList"
                                                value-prop="value"
                                                label="title"
                                                :searchable="true"
                                                placeholder="Select source"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="source" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Facebook Profile</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.facebook_id }">
                                        <Field autocomplete="off" name="facebook_id" type="text" v-model="form.facebook_id" placeholder="Enter Facebook Link Here.." />
                                    </div>
                                    <ErrorMessage name="facebook_id" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Twitter Page</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.twitter_id }">
                                        <Field autocomplete="off" name="twitter_id" type="text" v-model="form.twitter_id" placeholder="Enter Twitter Page Here.." />
                                    </div>
                                    <ErrorMessage name="twitter_id" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Linkedin Page</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.linkedin_id }">
                                        <Field autocomplete="off" name="linkedin_id" type="text" v-model="form.linkedin_id" placeholder="Enter Linkedin Page Here.." />
                                    </div>
                                    <ErrorMessage name="linkedin_id" class="text-danger" />
                                </div>
                                <div class="group_item">
                                    <label class="input_label">Youtube Page</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.youtube_id }">
                                        <Field autocomplete="off" name="youtube_id" type="text" v-model="form.youtube_id" placeholder="Enter Youtube Page Here.." />
                                    </div>
                                    <ErrorMessage name="youtube_id" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp" v-for="(customFields, c) of customFieldsChunk" :key="c">
                                <div class="group_item" v-for="(field, f) of customFields" :key="f">
                                    <label class="input_label">{{ field.name }}</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors[`custom_field[${field.id}]`] }">
                                        <Field autocomplete="off" :name="`custom_field[${field.id}]`" type="text" v-model="form.custom_field[field.id]" />
                                    </div>
                                    <ErrorMessage :name="`custom_field[${field.id}]`" class="text-danger" />
                                </div>
                            </div>
                        <!-- </div> -->
                    </div>
                    <div class="action_wpr">
                        <!-- <a class="more_btn" @click="more = !more"><i :class="!more ?'fas fa-plus-circle' : 'fas fa-minus-circle'"></i> {{!more ? 'More' : 'Less'}}..</a> -->
                        <button :disabled="contactLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                        <button :disabled="contactLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="contactLoader"></i> {{ contactLoader ? 'Saving' : 'Save' }}</button>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { VueTelInput } from 'vue3-tel-input'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import * as yup from 'yup'
    const _ = require('lodash')
    import constants from '@/utils/Constant'
    import 'vue3-tel-input/dist/vue3-tel-input.css'

    export default {
        name: 'Add Manual',

        data () {
            const schema = yup.object({
                first_name: yup.string().required().label('The first name'),
                email: yup.string().email().label('The email'),
                status: yup.string().required().label('The tab'),
                facebook_id: yup.string().url().label('The facebook profile'),
                twitter_id: yup.string().url().label('The twitter page'),
                linkedin_id: yup.string().url().label('The linkedin page'),
                youtube_id: yup.string().url().label('The youtube page'),
            });

            return {
                form: {
                    id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    gender: '',
                    number: '',
                    status: 'Prospect',
                    temperature: '',
                    last_action: '',
                    business_name: '',
                    business_number: '',
                    address: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    country: '',
                    timezone: '',
                    birthdate: '',
                    source: '',
                    facebook_id: '',
                    twitter_id: '',
                    linkedin_id: '',
                    youtube_id: '',
                    custom_field: [],
                },
                schema,
                more: false,
                lastActions: constants.last_actions,
                customFieldsChunk: [],
            };
        },

        props: {
            closeModal: Function,
            addContactWatcher: Number,
        },

        components: {
            Field,
            Form,
            ErrorMessage,
            VueTelInput,
        },

        watch: {
            addContactWatcher () {
                const vm = this;

                vm.resetForm();
            },

            'form.country' (country) {
                const vm = this;

                vm.getTimezonesByCountry({ country });
            },
        },

        computed: mapState({
            contactLoader: state => state.contactModule.contactSaveLoader,
            genderList: state => state.contactModule.genderList,
            statusesGroup: state => state.contactModule.statusesGroup,
            temperatureList: state => state.contactModule.temperatureList,
            sourceList: state => state.contactModule.sourceList,
            countries: state => state.commonModule.countries,
            timezones: state => state.commonModule.timezonesByCountry,
            customFieldsAll: state => state.contactModule.customFieldsAll,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
            vm.getCustomFields({ active: 1, type: 'all' });

            if (vm.countries.length == 0) {
                vm.getCountries();
            }

            vm.customFieldsChunk = _.chunk(_.toArray(vm.customFieldsAll), 2);
        },

        methods: {
            ...mapActions({
                getCountries: 'commonModule/getCountries',
                getTimezonesByCountry: 'commonModule/getTimezonesByCountry',
                saveContact: 'contactModule/saveContact',
                getCustomFields: 'contactModule/getCustomFields',
            }),

            resetForm () {
                const vm = this;

                vm.form = {
                    id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    gender: '',
                    number: '',
                    status: 'Prospect',
                    temperature: '',
                    last_action: '',
                    business_name: '',
                    business_number: '',
                    address: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    country: '',
                    timezone: '',
                    birthdate: '',
                    source: '',
                    facebook_id: '',
                    twitter_id: '',
                    linkedin_id: '',
                    youtube_id: '',
                    custom_field: [],
                };
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError   = setFieldError;
                vm.form.handleCancel    = vm.closeModal;

                vm.saveContact(vm.form);
            },

            handlePhoneNumber (phone, phoneObject, input) {
                const vm = this;

                if (phoneObject && phoneObject.number) {
                    vm.form.number = phoneObject.number
                }
            },
        },
    }
</script>

<style scoped>
    .modal_footer .btn {
        width: 140px;
    }
    .action_area {
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        margin: 20px auto 0 auto;
        max-width: 980px;
    }
    .fld_box{
        padding: 30px 40px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #f5f5f5;
        margin-bottom: 20px;
    }
    .more_btn{
        color: #121525;
    }
</style>
